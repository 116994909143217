.app-logo-main {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

.app-logo-main__text {
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
}
  
.app-logo-main__text img {
  width: 78px;
}

.app-logo-main__text div {
  color: var(--color-on-primary);
  white-space: nowrap;
  font-family: var(--font-family-secondary);
  font-size: 20.093px;
  font-weight: 700;
  line-height: 140%;
}

.app-logo__img-container {
  background: url('../../../images/logo-background-img.jpg') lightgray 0px 0px / 100% 100% no-repeat;
  margin-right: 16px;
  width: 46.56px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fade {
  0% {
      opacity: 0;
  }
  25% {
      opacity: 0.25;
  }
  50% {
      opacity: 0.5;
  }
  75% {
      opacity: 0.75;
  }
  100% {
      opacity: 1;
  }
}

.progress {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  width: 240px;
  animation-name: fade;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}


@media (max-width: 800px) {
  .app-logo__img-container {
    width: 31.04px;
    height: 32px;
    margin-right: 8px;
  }

  .app-logo-main__text {
    gap: 0;
  }

  .app-logo-main__text div {
    font-size: 13.395px;
  }
}
