.app {
    text-align: center;
    font-family: var(--font-family-regular);
    font-style: normal;
    color: var(--color-on-primary);
}

.app-container {
    min-height: calc(100vh - 55px);
}

.app--logo {
    height: 40px;
    pointer-events: none;
    top: 10px;
    text-align: center;
}

.app--page-content-container {
    margin: 40px;
}

h2 {
    font-size: 24px;
    line-height: 36px;
    margin: 12px 0 40px;
}

h3 {
    color: var(--color-on-primary);
    font-family: var(--font-family-secondary);
    font-size: 24px;
    font-weight: var(--font-weight-medium);
    line-height: 130%;
}

h4 {
    color: var(--color-on-primary);
    font-family: var(--font-family-secondary);
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    line-height: 140%;
}

h5 {
    font-size: 13px;
    line-height: 120%;
    font-weight: var(--font-weight-light-bold);
    margin: 0;
    color: var(--color-stone-gray-40);
}

h6 {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height);
}

@media (max-width: 1200px) {
    .app--page-content-container {
        margin: 40px 24px;
    }

    h2 {
        font-size: 24px;
        line-height: 29px;
    }

    h3 {
        color: var(--color-on-primary);
        font-family: var(--font-family-secondary);
        font-weight: var(--font-weight-medium);
        font-size: 21px;
        line-height: 130%;
    }

    h4 {
        font-size: var(--font-size-large);
    }
}

@media (max-width: 800px) {
    .app--page-content-container {
        margin: 24px 16px;
    }

    h3 {
        font-size: var(--font-size-large);
    }
}

:root {
    --color-background: #fff;
    --color-outline: #bcf1e9;
    --color-disabled: #bac0c2;
    --color-primary: #20d2b5;
    --color-active-status: #00b200;
    --color-on-primary: #0a3f36;
    --color-expired-status: #f00;
    --color-paused-status: #ffa500;
    --color-on-background: #0a3f36;
    --color-link: #009cec;
    --color-primitives-evergreen-evergreen10: #0a3f36;
    --color-primitives-evergreen-evergreen20: #10695b;
    --color-primitives-evergreen-evergreen30: #16937f;
    --color-primitives-evergreen-evergreen50: #4ddbc4;
    --color-primitives-evergreen-evergreen70: #a6ede1;
    --color-primitives-evergreen-evergreen80: #d2f6f0;
    --color-stone-gray-40: #1aa891;
    --color-primary-container: #e9faf8;
    --color-secondary: #53a8bc;
    --icon-size: 24px;
    --border-radius: 8px;
    --font-size-small: 14px;
    --font-size-regular: 16px;
    --font-size-large: 18px;
    --font-family-regular: Roboto;
    --font-family-secondary: Poppins;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-light-bold: 600;
    --font-weight-bold: 700;
    --line-height: 160%;
}
