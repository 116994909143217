.profile-menu {
    position: relative;
    display: inline-block;
}
  
.profile-menu__toggle {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    height: 48px;
    display: flex;
    align-items: center;
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-light-bold);
    line-height: 150%;
    padding: 0;
}

.profile-menu__icon {
    margin-right: 8px;
}

.profile-menu__items {
    position: absolute;
    top: 50px;
    right: 0;
    width: 230px;
    box-sizing: border-box;
    background: var(--color-background);
    box-shadow: 0px 4px 4px rgba(0,0,0,0.3);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-outline);
    padding: 4px 8px 8px 8px;
    z-index: 1;
    text-align: left;
}

.profile-menu__item {
    font-family: var( --font-family-regular);
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height);
    padding: 8px;
    cursor: pointer;
    background: var(--color-background);
    margin-top: 4px;
    border-radius: 2px;
    color: var(--color-on-primary);
}

.profile-menu__item-divider {
    height: 1px;
    margin-top: 4px;
    position: relative;
    background: var(--color-outline);
}

.profile-menu__item:hover {
    background-color: var(--color-primary-container);
    color: var(--color-on-primary);
}

.profile-menu__chevron {
    margin-left: 8px;
}

@media (max-width: 800px) {
    .profile-menu__username {
        display: none;
    }
    .profile-menu__chevron {
        display: none;
    }
}
