.app--footer__re-skin-light {
    margin: 0;
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary-container);
    color: var(--color-on-primary);
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height);
    height: 54px;
    box-sizing: border-box;
}

.privacy__re-skin-light a {
    text-decoration: none;
    color: var(--color-link);
    font-size: var(--font-size-small);
}

@media (max-width: 500px) {
    .app--footer__re-skin-light {
        height: 76px;
    }
}
