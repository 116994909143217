.something-went-wrong--container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 186px);
}

.something-went-wrong--img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.something-went-wrong--img-container svg path.wl-custom-color {
    max-width: 100%;
    height: auto;
    fill: var(--brand-id-primary-color);
}

.something-went-wrong--status-code {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-background);
    font-size: 34px;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-light-bold);
}

.something-went-wrong--static-msg {
    margin-top: -5px;
    margin-bottom: 0;
}

.something-went-wrong--error-msg {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-family-regular);
    margin: 0;
    margin-top: 8px;
}

.something-went-wrong--link {
    margin-top: 25px;
}

.something-went-wrong--button {
    display: flex;
    background-color: var(--brand-id-primary-color);
    border-radius: var(--border-radius);
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-secondary);
    line-height: 150%;
    padding: 10px 16px;
}

.something-went-wrong--bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-medium);
    line-height: 130%;
}

a {
    text-decoration: none;
    color: var(--color-background);
    font-size: var(--font-size-regular);
}

@media (max-width: 800px) {
    .something-went-wrong--bottom-content {
        font-size: 21px;
    }

    .something-went-wrong--static-msg {
        margin-top: -5px;
    }

    .something-went-wrong--error-msg {
        font-size: 15px;
    }

    .something-went-wrong--button {
        padding: 12px 16px;
        font-size: 15px;
        line-height: 150%;
    }
}

@media (max-width: 500px) {
    .something-went-wrong--static-msg {
        margin-top: -5px;
        font-size: var(--font-size-large);
    }

    .something-went-wrong--error-msg {
        font-size: var(--font-size-small);
    }

    .something-went-wrong--button {
        padding: 12px 12px;
        font-size: var(--font-size-small);
        line-height: 150%;
    }

    .something-went-wrong--img-container svg {
        width: 100%;
        height: 100%;
    }
}
