.locationGroups--container .common-table tr {
    background: var(--color-primary-container);
    border-radius: var(--border-radius);
}

.locationGroups--container .common-table th{
    background: var(--color-background);
    font: var(--font-weight-bold) var(--font-size-regular) / var(--line-height) var(--font-family-regular);
}

.locationGroups--container .common-table tr:hover td{
    background: var(--color-primitives-evergreen-evergreen80);
}

.locationGroups--container .common-table tr td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font: var(--font-weight-bold) var(--font-size-regular) / var(--line-height) var(--font-family-regular);
}

.locationGroups--container .common-table tr td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.table-view--pagination-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: var(--font-weight-bold);
    position: sticky;
    top: 100vh;
    background-color: var(--color-background);
    margin-bottom: 40px;
}

.table-view--pagination-desktop div {
    width: 74px;
    display: flex;
    height: 22px;
    justify-content: flex-start;
}

.table-view--pagination-desktop .MuiFormControl-root {
    margin: 0 5px;
    position: relative;
    top: 1px;
}

.table-view--pagination-desktop .MuiFormControl-root div {
    margin: 0;
}

.table-view--pagination-desktop .MuiFormControl-root div::before,
.table-view--pagination-desktop .MuiFormControl-root:hover div::before,
.table-view--pagination-desktop div::after {
    border-bottom: 0;
}

.table-view--pagination-desktop .MuiSvgIcon-root {
    color: var(--color-on-primary);
}

.table-view--pagination-desktop .MuiPagination-root svg path {
    fill: var(--color-on-primary);
}

.table-view--pagination-desktop .MuiPagination-root svg {
    height: 28px;
    width: 28px;
}

.table-view--icon-container {
    display: flex;
    color: #ccebe8 !important;
    opacity: 1 !important;
    flex-direction: row;
}

.MuiPagination-root button {
    font-weight: 700;
}

.table-view--loading {
    opacity: 0.1;
}

.table-view--table-container {
    height: calc(100vh - 184px);
    overflow: auto;
}

.MuiTableCell-root {
    position: relative;
}

.table-row--overlay-link {
    position: absolute;
    top: 0;
    height: 100%;
    width: calc(100vw - 160px);
    z-index: 1;
}

.table-cell-label {
    padding: 0 16px;
    position: relative;
}
.table-view--pagination-desktop .MuiFormControl-root {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    background: var(--color-primary-container);
    border-radius: var(--border-radius);
    border: 1px solid var(--brand-id-primary-color) !important;
    justify-content: flex-start;
    cursor: pointer;
    color: var(--color-on-primary);
    font-family: var(--font-family-regular);
    font-size: var(--font-size-regular);
    margin-right: 16px;
}

.MuiPagination-root {
    margin-left: 12px;
    margin-right: 12px;
}

.table-view--pagination-desktop .MuiPaginationItem-root {
    background-color: var(--color-background) !important;
    border: 1px solid var(--brand-id-primary-color) !important;
    cursor: pointer;
    padding: 10px 16px;
    margin: 0px;
    border-radius: 0px;
    color: var(--color-on-primary);
    border: 1px solid var(--color-primary);
    font: var(--font-weight-light-bold) var(--font-size-regular) / 150% var(--font-family-secondary);
    margin-right: -1px;
    min-width: 48px;
    height: 44px;
}

.table-view--pagination-desktop span {
    margin-right: 32px;
}

.table-view--pagination-desktop .MuiFormControl-root svg {
    margin-left: 10px;
}

.table-view--pagination-desktop .pagination-button {
    border-radius: var(--border-radius) !important;
}
.table-view--pagination-desktop .MuiPaginationItem-root:active {
    color: var(--color-primitives-evergreen-evergreen20);
    border: 1px solid var(--brand-id-primary-color);
}
.table-view--pagination-desktop .MuiPaginationItem-root:hover {
    color: var(--color-primitives-evergreen-evergreen30);
    border: 1px solid var(--brand-id-primary-color);
}
.table-view--pagination-desktop .pagination-button:disabled {
    opacity: 0.6;
    pointer-events: none;
}
