.location-group-card-cell--header {
    display: flex;
    align-items: center;
}

.location-group-card-cell--header:nth-child(2) {
    padding-left: 0.5rem;
}

.location-group-card-cell--header svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.3rem;
}

.location-group-card-cell--header svg path {
    fill: var(--brand-id-primary-color);
}

.location-group-card-cell--header,
.location-group-card-cell--hyper-strong {
    color: var(--color-on-primary);
    font: var(--font-weight-regular) 15px / var(--line-height) var(--font-family-regular);
}

.location-group-card-cell--status,
.location-group-card-cell--header {
    font-weight: var(--font-weight-bold);
}
.location-group-card-cell--header {
    margin-right: 16px;
}

@media (min-width: 340px) and (max-width: 500px) {
    .location-group-card-cell--header {
        font-size: var(--font-size-small);
    }
}

@media (max-width: 370px) {
    .location-group-card-cell--header {
        font-size: var(--font-size-small);
    }
}
