.mobile-view--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75rem 1.5rem 0.75rem;
    margin-bottom: 40px;
    max-height: calc(100vh - 60px);
    overflow-y: scroll;
}

.mobile-view-opaque-container {
    opacity: 0.5;
}

.mobile-view--no-location-groups-found {
    background: var(--color-primary-container);
    padding: 1rem;
    border-radius: 0.5rem;
}

@media (min-width: 340px) and (max-width: 540px) {
    .mobile-view--container {
        padding: 0.25rem 1rem 0.75rem;
    }
}
