.location-group-card--container {
    background: var(--color-primary-container);
    border: 1px solid var(--color-outline);
    border-radius: var(--border-radius);
    text-decoration: none;
    color: var(--color-on-primary);
    margin: 0.75rem 0;
    word-break: break-all;
    text-align: left;
}

.location-group-card--header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--color-outline);
}

.location-group-card--body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--color-outline);
    gap: 32px;
}

.location-group-card--footer {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
}

.location-group-card--strong,
.location-group-card--extra-strong,
.location-group-card--title {
    color: var(--color-on-primary);
    font: var(--font-weight-regular) 15px / var(--line-height) var(--font-family-regular);
}

.location-group-card--title {
    font-weight: var(--font-weight-bold);
}

.location-group-card--footer .location-group-card-cell--header:first-child svg circle,
.location-group-card--footer .location-group-card-cell--header:first-child svg path {
    stroke: var(--brand-id-primary-color);
}

@media (max-width: 500px) {
    .location-group-card--container {
        font-size: 0.7rem;
    }

    .location-group-card--extra-strong,
    .location-group-card--strong {
        font-size: var(--font-size-small);
    }

    .location-group-card--title,
    .location-group-card-cell--hyper-strong {
        margin-bottom: 0;
        font-size: var(--font-size-small);
    }

    .location-group-card--body {
        padding: 1rem;
    }

    .location-group-card--header {
        padding: 1rem;
    }

    .location-group-card--footer {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }
}
