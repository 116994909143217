a.MuiTableRow-root {
    text-decoration: none;
}

.locationGroups--container {
    border-top: 1px solid var(--color-outline);
}

.locationGroups--container .common-table table {
    padding: 0 40px 24px;
}

.MuiPaginationItem-root.Mui-selected {
    background-color: var(--color-background) !important;
    border: 3px solid var(--brand-id-primary-color);
}

.locationGroups--container .common-table tr td {
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px solid var(--color-outline);
}

.MuiPaginationItem-root.Mui-selected {
    background: var(--color-primary-container) !important;
    border: 2px solid var(--brand-id-primary-color) !important;
}

.MuiPaginationItem-root.Mui-selected:hover {
    background-color: var(--color-primitives-evergreen-evergreen50) !important;
    color: var(--color-primitives-evergreen-evergreen30) !important;
}

.MuiPagination-root .MuiPagination-ul > li:first-of-type button {
    border-radius: var(--border-radius) 0px 0px var(--border-radius);
}
.MuiPagination-root .MuiPagination-ul > li:last-of-type button {
    border-radius: 0px var(--border-radius) var(--border-radius) 0px;
}

/* Below CSS is count when there is search functionality is implemented */
.location-groups--search-count {
    padding: 1rem;
    font-weight: var(--font-weight-medium);
    font-family: var(--font-family-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.location-groups--empty-search {
    min-height: calc(100vh - 186px);
    font-size: 24px;
}

.location-groups--search-query {
    font-weight: 700;
}

.location-groups--empty-search-results svg path.wl-custom-color {
    fill: var(--brand-id-primary-color);
    padding: 2rem;
}

@media (max-width: 800px) {
    .location-groups--empty-search {
        font-size: 21px;
    }
}

@media (max-width: 500px) {
    .location-groups--empty-search {
        font-size: var(--font-size-large);
    }

    .location-groups--empty-search-results svg {
        width: 100%;
        height: 100%;
    }
}
