.ham-menu--wrapper {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    outline: inherit;
    cursor: pointer;
    margin-right: 16px;
}

.ham-menu--drawer-wrapper {
    width: 320px;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
}

.MuiDrawer-root .MuiPaper-root {
    height: calc(100% - 72px);
    top: 72px;
}

.ham-menu--drawer-wrapper {
    height: 100%;
    background-color: var(--color-on-primary);
}
