.app-header {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    margin: 0;
    justify-content: space-between;
    padding: 12px 40px;
    box-sizing: border-box;
    height: 72px;
}

.app-header.search-open {
    align-items: flex-start;
}

.app-header__left-group {
    display: flex;
}

.app-header__search-group {
    display: flex;
    align-items: center;
}

.search-bar-v2 {
    margin-right: 40px;
}

.app--button-navigate-back {
    border-style: none;
    background-color: transparent;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .search-bar-v2 {
        margin-right: 20px;
    }
}

@media (max-width: 800px) {
    .search-bar-v2 {
        margin-right: 0;
    }

    .app-header.search-open .app-header__left-group {
        margin-top: 8px;
    }

    .app-header.search-open {
        height: 130px;
    }

    .app-header {
        padding: 12px 16px;
    }
}
