.available-apps__header {
    color: var(--color-background);
    opacity: 0.5;
    margin: 0;
    padding: 8px 16px;
}

.available-apps-container {
    padding: 0 16px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-top: 4px;
}

.available-apps__app-btn {
    width: 119px;
    height: 79px;
    display: flex;
    padding: 16px 8px;
    justify-content: center;
    border-radius: var(--border-radius);
    border: 0.75px solid var(--color-outline);
    cursor: pointer;
}

.available-apps__app-btn:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), var(--color-on-background);
}

.available-apps__app-btn button {
    background: none;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.app-logo-container {
    background: url('../../images/logo-background-img.jpg'), lightgray 0px 0px / 100% 100% no-repeat;
    width: 35px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.gt-logo {
    width: 16px;
    height: 23px;
}

.app-name {
    font-family: var(--font-family-secondary);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    line-height: 140%;
    color: var(--color-background);
}
